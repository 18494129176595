<template>
  <div class="home">
    <el-carousel trigger="click" :height="bannerHeight + 'px'">
      <el-carousel-item v-for="item in carouselList" :key="item.id">
        <img class="Image" :src="item.site.url + item.image" alt="Image" />
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'

export default {
  name: 'ComPany',
  props: ['carouselList'],
  data() {
    return {
      screenWidth: window.innerWidth,
      bannerHeight: 0
    }
  },
  mounted() {
    this.bannerHeight = 833 / 2100 * this.screenWidth
    window.onresize = () => {
      this.screenWidth = window.innerWidth
      this.bannerHeight = 833 / 2100 * this.screenWidth
    }
  }
}
</script>
<style scoped>
/* main部分 */
/* .home{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
} */
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}
@media (min-width: 2000px) {
  .home {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .el-carousel {
    width: 100%;
    height: 85vh;
  }
}
.Image {
  height: 100%;
  width: 100%;
  /* object-fit: cover; */
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}
</style>
