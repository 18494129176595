<template>
  <div id="container"></div>
</template>
<script>
import AMapLoader from '@amap/amap-jsapi-loader'
export default {
  name: 'MapContainer',
  mounted() {
    this.initAMap()
  },
  unmounted() {
    this.map?.destroy()
  },
  destroyed() {
    this.map.destroy()
    this.map = null
    this.mapModule = null
  },
  methods: {
    initAMap() {
      window._AMapSecurityConfig = {
        securityJsCode: '70762d4e1ae7ae0f335b672510624f5e'
      }
      AMapLoader.load({
        key: '34a2929c36a5e41fdd3b05e49f0cfd77', // 申请好的Web端开发者Key，首次调用 load 时必填
        version: '2.0', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ['AMap.Scale'] // 需要使用的的插件列表，如比例尺'AMap.Scale'，支持添加多个如：['...','...']
      })
        .then(AMap => {
          this.map = new AMap.Map('container', {
            // 设置地图容器id
            viewMode: '3D', // 是否为3D地图模式
            zoom: 18, // 初始化地图级别
            center: [104.073916, 30.629871] // 初始化地图中心点位置
          })
          // 点标记显示内容
          const position = new AMap.LngLat(104.073916, 30.629871) // Marker 经纬度
          const icon = new AMap.Icon({
            size: new AMap.Size(50, 60), // 图标尺寸
            image: '//a.amap.com/jsapi_demos/static/demo-center/icons/dir-marker.png', // Icon 的图像
            imageOffset: new AMap.Pixel(-90, -3), // 图像相对展示区域的偏移量，适于雪碧图等
            imageSize: new AMap.Size(135, 40) // 根据所设置的大小拉伸或压缩图片
          })
          const marker = new AMap.Marker({
            position,
            icon,
            offset: new AMap.Pixel(-13, -30) // 以 icon 的 [center bottom] 为原点
          })
          this.map.add(marker)
        })
        .catch(e => {
          console.log(e)
        })
    }
  }
}
</script>
<style scoped>
#container {
  padding: 0px;
  margin: 0px;
  width: 100%;
  height: 400px;
}
.custom-content-marker {
  position: relative;
  width: 25px;
  height: 34px;
}

.custom-content-marker img {
  width: 100%;
  height: 100%;
}

.custom-content-marker .close-btn {
  position: absolute;
  top: -6px;
  right: -8px;
  width: 15px;
  height: 15px;
  font-size: 12px;
  background: #ccc;
  border-radius: 50%;
  color: #fff;
  text-align: center;
  line-height: 15px;
  box-shadow: -1px 1px 1px rgba(10, 10, 10, 0.2);
}

.custom-content-marker .close-btn:hover {
  background: #666;
}
</style>
