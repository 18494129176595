<template>
  <div class="container">
    <div class="top">
      <img src="../assets/pagingBanner1.jpg" alt />
    </div>
    <div class="butom">
      <div class="contact">
        <div class="contact_one">
          <h2>联系我们</h2>
          <span>{{talentConcept.simple}}</span>
        </div>
        <div class="contact_two">
          <div class="contact_two_a">
            <img src="../assets/phone.png" alt />
            <div class="contact_two_in">
              <p>服务热线</p>
              <span>{{talentConcept.telephone}}</span>
            </div>
          </div>
          <div class="contact_two_a">
            <img src="../assets/email.png" alt />
            <div class="contact_two_in">
              <p>公司邮箱</p>
              <span>{{talentConcept.email}}</span>
            </div>
          </div>
          <div class="contact_two_a">
            <img src="../assets/chuanzhen.png" alt />
            <div class="contact_two_in">
              <p>传真</p>
              <span>{{talentConcept.fax}}</span>
            </div>
          </div>
          <div class="contact_two_a">
            <img src="../assets/address.png" alt />
            <div class="contact_two_in">
              <p>公司地址</p>
              <span>{{talentConcept.address}}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="map">
        <!-- <img src="../assets/map2.jpg" alt /> -->
        <MapContainer></MapContainer>
      </div>
    </div>
  </div>
</template>
<script>
// import { dataSortByParam } from '@/utils/lls'
import MapContainer from '@/components/MapContainer.vue'
import { getSite } from '@/api/index'
export default {
  components: {
    MapContainer
  },
  data() {
    return {
      activeName: 'first',
      talentConcept: [],
      images: []
    }
  },
  mounted() {
    this.getContactUs()
  },
  methods: {
    // 请求联系我们
    getContactUs() {
      getSite().then(res => {
        // this.talentConcept = dataSortByParam(res.data, 'sticky')
        this.talentConcept = res.data.customs
      })
    },
    handleClick(tab, event) {
      // console.log(tab, event)
    }
  }
}
</script>
<style scoped>
.container {
  width: 100%;
  /* height: 1000px; */
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  flex-direction: column;
}
@media (min-width: 2000px) {
  .container {
    width: 100%; /* 在屏幕宽度大于或等于600px时，宽度设置为50% */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* outline: 1px solid red; */
  }
}
.top {
  width: 100%;
  height: 300px;
  overflow: hidden;
  position: relative;
}
.top img {
  width: 100%;
  height: 300px;
}
.top img:hover {
  transition: all 1.5s cubic-bezier(0.215, 0.61, 0.215, 1);
  transform: scale(1.1);
}
.butom {
  width: 80%;
  height: 800px;
  background-color: white;
  z-index: 999;
  margin-bottom: 20px;
  margin-top: -80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  /* outline: 1px solid red; */
}

/* 新版块 */
.contact {
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-weight: 800;
  /* outline: 1px solid rgb(11, 10, 10); */
}
.contact_one {
  width: 100%;
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin-left: 20px; */
  /* outline: 1px solid red; */
}
.contact_one h2 {
  font-size: 30px;
}
.contact_one span {
  display: inline-block;
  width: 100%;
  font-size: 14px;
  /* text-align: left; */
}

.contact_two {
  width: 100%;
  height: 60%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;
  /* outline: 1px solid red; */
}

.contact_two_a {
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  padding: 10px;
  /* outline: 1px solid red; */
}
.contact_two_a:hover {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.contact_two_a img {
  width: 60px;
  height: 60px;
  /* outline: 1px solid red; */
}
.contact_two_in {
  width: 100%;
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* outline: 1px solid red; */
  font-size: 16px;
}
.map {
  width: 100%;
  height: 50%;
  display: flex;
  /* flex-direction: column; */
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  /* outline: 1px solid red; */
}
.map img {
  width: 100%;
  height: 90%;
  /* object-fit: contain; */
}
</style>
