<template>
  <div class="container">
    <div class="top">
      <img src="../assets/page_banner.jpg" alt />
    </div>
    <div class="butom" ref="butom">
      <el-tabs v-model="activeName" :before-leave="beforeLeave" :stretch="true">
        <el-tab-pane label="输变电工程项目" name="substationProject">
          <div class="second">
            <template v-if="newSubstation[asideNavName] && newSubstation[asideNavName].length > 0">
              <!-- 接入数据 -->
              <div
                class="second_left"
                v-for="(item, index) in newSubstation[asideNavName]"
                :key="index"
              >
                <div class="second_left_img">
                  <img :src="item.site.url + item.image" alt />
                </div>
                <div class="second_left_text">
                  <strong @click="goToDetail(newSubstation[asideNavName][index])">{{ item.title }}</strong>
                  <!-- <em v-for="(item, index) in items" :key="index">{{ item.name }}</em> -->
                  <p>
                    {{ item.description }}
                    <span
                      @click="goToDetail(newSubstation[asideNavName][index])"
                    >[详情]</span>
                  </p>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="empty-info">
                <el-empty description="暂无更多数据"></el-empty>
              </div>
            </template>
          </div>
        </el-tab-pane>
        <el-tab-pane label="水利水电项目" name="hydropowerProject">
          <div class="second">
            <template v-if="newHydropower[asideNavName] && newHydropower[asideNavName].length > 0">
              <!-- 接入数据 -->
              <div
                class="second_left"
                v-for="(item, index) in newHydropower[asideNavName]"
                :key="index"
              >
                <div class="second_left_img">
                  <img :src="item.site.url + item.image" alt />
                </div>
                <div class="second_left_text">
                  <strong @click="goToDetail(newHydropower[asideNavName][index])">{{ item.title }}</strong>
                  <!-- <em v-for="(item, index) in items" :key="index">{{ item.name }}</em> -->
                  <p>
                    {{ item.description }}
                    <span
                      @click="goToDetail(newHydropower[asideNavName][index])"
                    >[详情]</span>
                  </p>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="empty-info">
                <el-empty description="暂无更多数据"></el-empty>
              </div>
            </template>
          </div>
        </el-tab-pane>
        <el-tab-pane label="轨道交通项目" name="trafficProject">
          <div class="second">
            <template v-if="newTraffic[asideNavName] && newTraffic[asideNavName].length > 0">
              <!-- 接入数据 -->
              <div
                class="second_left"
                v-for="(item, index) in newTraffic[asideNavName]"
                :key="index"
              >
                <div class="second_left_img">
                  <img :src="item.site.url + item.image" alt />
                </div>
                <div class="second_left_text">
                  <strong @click="goToDetail(newTraffic[asideNavName][index])">{{ item.title }}</strong>
                  <p>
                    {{ item.description }}
                    <span
                      @click="goToDetail(newTraffic[asideNavName][index])"
                    >[详情]</span>
                  </p>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="empty-info">
                <el-empty description="暂无更多数据"></el-empty>
              </div>
            </template>
          </div>
        </el-tab-pane>
        <el-tab-pane label="工业服务" name="industrialService">
          <div class="second">
            <template v-if="newIndustrial[asideNavName] && newIndustrial[asideNavName].length > 0">
              <!-- 接入数据 -->
              <div
                class="second_left"
                v-for="(item, index) in newIndustrial[asideNavName]"
                :key="index"
              >
                <div class="second_left_img">
                  <img :src="item.site.url + item.image" alt s />
                </div>
                <div class="second_left_text">
                  <strong @click="goToDetail(newIndustrial[asideNavName][index])">{{ item.title }}</strong>
                  <p>
                    {{ item.description }}
                    <span
                      @click="goToDetail(newIndustrial[asideNavName][index])"
                    >[详情]</span>
                  </p>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="empty-info">
                <el-empty description="暂无更多数据"></el-empty>
              </div>
            </template>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div
      :class="['aside-nav', asideNavFixed ? 'aside-nav-fixed' : '']"
      v-if="asideNav[activeName] && asideNav[activeName].length > 1"
    >
      <!-- <el-tabs type="card" class="nav-container" tab-position="left" v-model="asideNavName">
        <el-tab-pane
          class="nav-item"
          :label="item.name"
          :name="item.name"
          v-for="(item, index) in asideNav[activeName]"
          :key="index"
        ></el-tab-pane>
      </el-tabs>-->
      <div class="nav-container">
        <div
          :class="['nav-item', item.name === asideNavName ? 'nav-item-active' : '']"
          v-for="(item, index) in asideNav[activeName]"
          @click="handleAsideNav(item.name)"
          :key="index"
        >{{ item.name }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { getArticle, getChannelList } from '@/api/index'
import { dataSortByParam } from '@/utils/lls'
export default {
  data() {
    return {
      activeName: this.$route.params.name || 'substationProject',
      newSubstation: {},
      newHydropower: {},
      newTraffic: {},
      newIndustrial: [],
      asideNav: {}, // 侧边导航
      asideNavName: '全部',
      navFixedTop: 120, // 侧边导航固定距离
      asideNavFixed: false // 侧边导航固定状态
    }
  },
  watch: {
    '$route.params.name'(to, from) {
      this.activeName = this.$route.params.name
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
    this.getSubstation()
    this.getHydropower()
    this.getTraffic()
    this.getIndustrial()
  },
  methods: {
    // 请求输变电工程项目
    getSubstation() {
      const param = {
        channel: 'substationProject'
      }
      this.setChannelList(param.channel)
      getArticle(param).then(res => {
        this.newSubstation = this.dataSplit(res.data)
      })
    },
    // 请求水利水电项目
    getHydropower() {
      const param = {
        channel: 'hydropowerProject'
      }
      this.setChannelList(param.channel)
      getArticle(param).then(res => {
        this.newHydropower = this.dataSplit(res.data)
        // console.log(this.substation)
        // this.items = this.substation[0].tags
        // console.log(this.items)
      })
    },
    // 请求轨道交通项目
    getTraffic() {
      const param = {
        channel: 'trafficProject'
      }
      this.setChannelList(param.channel)
      getArticle(param).then(res => {
        this.newTraffic = this.dataSplit(res.data)
      })
    },
    // 请求水利水电项目
    getIndustrial() {
      const param = {
        channel: 'industrialService'
      }
      this.setChannelList(param.channel)
      getArticle(param).then(res => {
        this.newIndustrial = this.dataSplit(res.data)
      })
    },

    goToDetail(item) {
      this.$router.push({ name: 'projectdetails', params: { data: item } })
    },
    beforeLeave(activeName, oldName) {
      this.asideNavName = '全部'
    },
    /**
     * 根据栏目名分割对象数组 为数组对象
     */
    dataSplit(arr) {
      const arrObject = {}
      arr.forEach(item => {
        arrObject[item.channel.name] = arrObject[item.channel.name] || []
        arrObject[item.channel.name].push(item)
      })
      for (const key in arrObject) {
        arrObject[key] = dataSortByParam(arrObject[key], 'sticky')
      }
      arrObject['全部'] = dataSortByParam(arr, 'sticky')
      return arrObject
    },
    /**
     * 根据父级栏目名获取并添加子级栏目到侧边导航
     */
    setChannelList(channelName) {
      let tempList = []
      getChannelList({ parent: channelName }).then(res => {
        tempList = res.data
        tempList.unshift({ name: '全部' })
        this.asideNav[channelName] = tempList
      })
    },
    // 侧边导航点击事件
    handleAsideNav(name) {
      this.asideNavName = name
    },
    // 监听内容体距顶部高度，改变侧边导航样式
    handleScroll() {
      this.$nextTick(() => {
        if (this.$refs.butom.getBoundingClientRect().top <= this.navFixedTop) {
          this.asideNavFixed = true
        } else {
          this.asideNavFixed = false
        }
      })
    }
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  }
}
</script>
<style scoped>
.container {
  width: 100%;
  /* height: 1000px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

@media (min-width: 2000px) {
  .container {
    width: 100%;
    /* 在屏幕宽度大于或等于600px时，宽度设置为50% */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* outline: 1px solid red; */
  }
}

.top {
  width: 100%;
  height: 300px;
  overflow: hidden;
  position: relative;
}

.top img {
  width: 100%;
  height: 300px;
}

.top img:hover {
  transition: all 1.5s cubic-bezier(0.215, 0.61, 0.215, 1);
  transform: scale(1.1);
}

.butom {
  width: 80%;
  /* height: 600px; */
  background-color: white;
  z-index: 999;
  margin-bottom: 0px;
  margin-top: -80px;
  /* outline: 1px solid red; */
}

.el-tabs >>> .el-tabs__item {
  height: 80px;
  line-height: 80px;
}

.second {
  width: 100%;
  /* height: 500px; */
  /* background-color: white; */
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  /* outline: 1px solid red; */
}

.second_left {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  /* background-color: azure; */
  /* outline: 1px solid red; */
}

.second_left_img {
  width: 40%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: bisque; */
}

.second_left_img img {
  width: 90%;
  height: 280px;
  border-radius: 20px;
  /* background-color: bisque; */
}

.second_left_text {
  width: 60%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  padding-left: 20px;
}

.second_left_text strong {
  display: block;
  font-size: 22px;
  line-height: 24px;
  cursor: pointer;
  /* outline: 1px solid red; */
}

.second_left_text em {
  display: block;
  font-family: 'Novecento Normal';
  color: #f39800;
  font-size: 24px;
  font-weight: bold;
  margin-top: 15px;
}

.second_left_text p {
  display: inline-block;
  width: 90%;
  height: 30%;
  color: #999;
  line-height: 1.9;
  font-size: 15px;
  margin-top: 10px;
  text-align: left;
  /* outline: 1px solid red; */
}

.second_left_text p span {
  color: #f39800;
  margin-left: 10px;
  font-weight: bold;
  cursor: pointer;
}

.second_rescher {
  width: 30%;
  height: 500px;
  /* outline: 1px solid red; */
}

.empty-info {
  width: 100%;
  display: flex;
  justify-content: center;
}

/* 侧边导航2.0 */
.aside-nav {
  min-width: 80px;
  position: absolute;
  left: 0;
  top: 55%;
  z-index: 9999;
}

.aside-nav-fixed {
  position: fixed;
  top: 28%;
}

.nav-item {
  height: 50px;
  margin-bottom: 5px;
  width: 80px;
  border-radius: 0 10px 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #2196f3;
  color: #fff;
  transition: all 0.4s ease 0s;
}

.nav-item:hover {
  width: 120px;
  transition: all 0.4s ease 0s;
  cursor: pointer;
}

.nav-item-active {
  width: 120px;
  font-weight: bolder;
  background-color: #555555;
}

/* 侧边导航1.0 */
/* .aside-nav-fixed {
  position: fixed;
  width: 150px;
  left: 0px;
  top: 200px;
  z-index: 9999;
  background-color: rgb(0, 0, 0, 0.1);
}
.nav-container {
  height: 250px;
}
.nav-container /deep/ .el-tabs__item {
  width: 150px;
  height: 50px;
  line-height: 50px;
  font-size: 16px;
  margin-top: 10px;
  background-color: #2180fd;
}
.nav-container /deep/ .is-left {
  text-align: center !important;
  border-radius: 10px;
}
.nav-item {
  height: 100px;
  outline: 1px solid red;
} */

/* 1111 */

/* .tab {
  display: flex;
}

.tab button {
  padding: 10px 20px;
  cursor: pointer;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
}

.tab button.active {
  background-color: #e9e9e9;
}

.tab-content div {
  display: none;
}

.tab-content div.active {
  display: block;
} */
</style>
