<template>
  <div class="container">
    <div class="top">
      <img src="../assets/pagingBanner1.jpg" alt />
    </div>
    <div class="butom">
      <el-tabs v-model="activeName" @tab-click="handleClick" :stretch="true">
        <el-tab-pane label="公司简介" name="company">
          <div class="second">
            <div class="company_one">
              <span>关于力隆升</span>
              <span>STAUCTURE</span>
            </div>
            <div class="company_two" v-if="company[0]">
              <div class="company_img">
                <img :src="company[0].site.url + company[0].image" alt />
              </div>
              <div class="company_text">
                <div class="company_text_in">
                  <h2>公司介绍</h2>
                  <span>CompanyIntroduction</span>
                  <p></p>
                </div>
                <!-- <div class="company_text_mid">
                    <span></span>
                </div>-->
                <div class="second_text" v-html="company[0].text"></div>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="资质荣誉" name="certificate">
          <div class="honor">
            <div class="honor_one">
              <span>资质荣誉</span>
              <span>QUAlIFICATION AND HONOR</span>
            </div>
            <!-- 资质 -->
            <h2>我们的资质</h2>
            <div class="honor_two">
              <div class="honor_box" v-for="(item, index) in qualification" :key="index">
                <div class="img">
                  <el-image
                    :src="item.site.url + item.image"
                    :preview-src-list="certificate"
                    @click="previewImg(index)"
                    :initial-index="initialIndex"
                    fit="contain"
                  ></el-image>
                </div>
                <span>{{item.title}}</span>
              </div>
            </div>
            <!-- 荣誉 -->
            <h2>我们的荣誉</h2>
            <div class="honor_two">
              <div class="honor_box" v-for="(item, index) in honer" :key="index">
                <div class="img">
                  <el-image
                    :src="item.site.url + item.image"
                    :preview-src-list="certificate"
                    @click="previewImg(qualification.length + index)"
                    :initial-index="initialIndex"
                    fit="contain"
                  ></el-image>
                  <!-- <img :src="item.site.url + item.image" alt /> -->
                </div>
                <span>{{item.title}}</span>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="组织架构" name="architecture">
          <div class="framework" v-for="(item, index) in architecture" :key="index">
            <div class="framework_one">
              <span>组织架构图</span>
              <span>STAUCTURE</span>
            </div>
            <div class="framework_two">
              <img :src="item.site.url + item.image" alt />
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="领导团队" name="team">
          <div class="temer">
            <div class="temer_one">
              <span>领导团队</span>
              <span>LEADING TEAM</span>
            </div>
            <div class="temer_two">
              <div class="temer_box" v-for="(item, index) in team" :key="index">
                <div class="temer_box_img">
                  <img :src="item.site.url + item.image" alt />
                </div>
                <div class="item">
                  <h2>{{ item.title }}</h2>
                  <p>{{ item.customs.companyName }}&nbsp;&nbsp;{{ item.customs.post }}</p>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane class="newbox" label="新闻资讯" name="news">
          <div class="new_one">
            <span>新闻资讯</span>
            <span>NEWS AND INFORMATION</span>
          </div>
          <div class="main_new">
            <div class="news" v-for="(item, index) in news" :key="index">
              <div class="news_left">
                <img :src="item.site.url + item.image" alt />
                <!-- <p>{{item.image}}</p> -->
              </div>
              <div class="news_right">
                <h3 @click="goToDetail(news[index])">{{ item.title }}</h3>
                <p>
                  {{ item.description }}
                  <span @click="goToDetail(news[index])">[详情]</span>
                </p>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import { dataSortByParam } from '@/utils/lls'
import { getArticle } from '@/api/index'
export default {
  data() {
    return {
      activeName: this.$route.params.name || 'company',
      // tabList: ['company', 'architecture', 'team'],
      architecture: [],
      company: [],
      team: [],
      tabData: [],
      news: [],
      qualification: [], // 资质
      honer: [], // 荣誉
      certificate: [], // 资质荣誉 预览list
      initialIndex: 0 // 当前图片预览index
    }
  },
  watch: {
    '$route.params.name'(to, from) {
      this.activeName = this.$route.params.name
    }
  },
  mounted() {
    this.getCompanyList()
    this.getArchitecTure()
    this.getTeamList()
    this.getNewList()
    this.getQualificaTion()
    this.setCertificate()
  },
  methods: {
    getCompanyList() {
      const param = {
        channel: 'company'
      }
      getArticle(param).then(res => {
        // console.log(res.data)
        this.company = dataSortByParam(res.data, 'sticky')
      })
    },
    // 请求资质
    getQualificaTion() {
      const param = {
        channel: 'qualification'
      }
      getArticle(param).then(res => {
        this.qualification = dataSortByParam(res.data, 'sticky')
        this.getHoNer()
      })
    },
    // 请求荣誉
    getHoNer() {
      const param = {
        channel: 'honor'
      }
      getArticle(param).then(res => {
        this.honer = dataSortByParam(res.data, 'sticky')
        this.setCertificate()
      })
    },
    setCertificate() {
      for (const item of this.qualification) {
        this.certificate.push(item.site.url + item.image)
      }
      for (const item of this.honer) {
        this.certificate.push(item.site.url + item.image)
      }
    },
    previewImg(index) {
      this.initialIndex = index
    },
    // 请求architecture
    getArchitecTure() {
      const param = {
        channel: 'architecture'
      }
      getArticle(param).then(res => {
        this.architecture = dataSortByParam(res.data, 'sticky')
        // console.log(this.architecture, 'architecture')
      })
    },
    // 请求team
    getTeamList() {
      const param = {
        channel: 'team'
      }
      getArticle(param).then(res => {
        this.team = dataSortByParam(res.data, 'sticky')
      })
    },
    // 请求新闻资讯
    getNewList() {
      const param = {
        channel: 'news'
      }
      getArticle(param).then(res => {
        this.news = dataSortByParam(res.data, 'sticky')
      })
    },
    // 跳转详情
    goToDetail(item) {
      this.$router.push({ name: 'newdetaile', params: { data: item } })
    },
    handleClick(tab, event) {
      this.activeName = tab.name
    }
  }
}
</script>
<style scoped>
.container {
  width: 100%;
  /* height: 1000px; */
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  flex-direction: column;
}
@media (min-width: 2000px) {
  .container {
    width: 100%; /* 在屏幕宽度大于或等于600px时，宽度设置为50% */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* outline: 1px solid red; */
  }
  .second {
  width: 100%;
  height: 800px;
  background-color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-bottom: 50px;
  /* margin-top: 50px; */
  /* outline: 1px solid red; */
}
.second .company_two {
  width: 80%;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  /* outline: 1px solid red; */
  /* flex-direction: column; */
}
.company_two .company_img {
  width: 20%;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #1e2432;
  /* outline: 1px solid red; */
}
.company_img img {
  width: 80%;
  height: 100%;
  /* height: 300px; */
  margin-left: 300px;
  object-fit: contain;
}
.company_text {
  width: 60%;
  /* height: 600px; */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* outline: 1px solid red; */
  font-size: 12px;
}
.second_text {
  width: 60%;
  height: 500px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  /* outline: 1px solid red; */
}
.second_text /deep/ p {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  /* outline: 1px solid red; */
}
}
.top {
  width: 100%;
  height: 300px;
  overflow: hidden;
  position: relative;
}
.top img {
  width: 100%;
  height: 300px;
}
.top img:hover {
  transition: all 1.5s cubic-bezier(0.215, 0.61, 0.215, 1);
  transform: scale(1.1);
}
.butom {
  width: 80%;
  min-height: 600px;
  background-color: white;
  z-index: 999;
  margin-bottom: 0px;
  margin-top: -80px;
  /* outline: 1px solid red; */
}
.el-tabs >>> .el-tabs__item {
  height: 80px;
  line-height: 80px;
}
/* 公司简介 */
.second {
  width: 100%;
  /* height: 800px; */
  background-color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-bottom: 50px;
  /* margin-top: 50px; */
  /* outline: 1px solid red; */
}
.second .company_one {
  width: 90%;
  height: 100px;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: column;
}
.second .company_one span:nth-of-type(1) {
  font-size: 30px;
  font-weight: 600;
}
.second .company_one span:nth-of-type(2) {
  font-size: 30px;
  font-weight: 600;
  color: #eddbcf;
}
.second .company_two {
  width: 90%;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  /* outline: 1px solid red; */
  /* flex-direction: column; */
}
.company_two .company_img {
  width: 40%;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #1e2432;
}
.company_img img {
  width: 100%;
  height: 100%;
  /* height: 300px; */
  margin-left: 100px;
  object-fit: contain;
}
.company_text {
  width: 60%;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* outline: 1px solid red; */
  font-size: 12px;
}
.company_text_in {
  width: 80%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  /* outline: 1px solid red; */
}
.company_text_mid {
  width: 100%;
  height: 100px;
}
.company_text_in p {
  display: inline-block;
  width: 50px;
  height: 5px;
  background-color: #f39800;
}
.second_text /deep/ p {
  margin: 0;
  padding: 0;
}
.second_text {
  width: 80%;
  height: 600px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  flex-direction: column;
  /* outline: 1px solid red; */
}

/* 荣誉资质 */
.honor {
  width: 100%;
  /* height: 600px; */
  background-color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-bottom: 50px;
}
.honor .honor_one {
  width: 90%;
  height: 100px;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: column;
}
.honor .honor_one span:nth-of-type(1) {
  font-size: 30px;
  font-weight: 600;
}
.honor .honor_one span:nth-of-type(2) {
  font-size: 30px;
  font-weight: 600;
  color: #eddbcf;
}
.honor_two {
  width: 90%;
  /* height: 500px; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 30px;
  /* outline: 1px solid red; */
}
.honor_box {
  width: 20%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: 25px;
  margin-right: 25px;
  margin-bottom: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  /* outline: 1px solid red; */
}

.honor_box .img {
  width: 90%;
  height: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  /* outline: 1px solid red; */
}
.img_a {
  width: 95%;
  height: 95%;
  z-index: 4;
  background-color: rgb(255, 255, 255);
}
/* 鎏金光圈 */
/* .img::before {
  content: '';
  position: absolute;
  left: -50%;
  top: -50%;
  width: 200%;
  height: 200%;
  border-radius: 10px;
  background-image: conic-gradient(transparent, #f6ea04, transparent 30%);
  z-index: 2;
  animation: rotation 4s linear infinite;
}
@keyframes rotation {
  to {
    transform: rotate(360deg);
  }
}
.img::after {
  content: '';
  position: absolute;
  --g: 4px;
  width: calc(100% - var(--g) * 2);
  height: calc(100% - var(--g) * 2);
  background: rgb(255, 255, 255);
  left: var(--g);
  top: var(--g);
  border-radius: inherit;
  z-index: -1;
  opacity: 0.9;
} */
/* text */
.img .el-image {
  width: 100%;
  height: 100%;
  z-index: 5;
  margin: 0;
  padding: 0;
  /* background-color: #005bac; */
}
.honor_box span {
  font-size: 12px;
}
/* 组织架构 */
.framework {
  width: 100%;
  /* height: 600px; */
  background-color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}
.framework_one {
  width: 90%;
  height: 100px;
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: column;
}
.framework_one span:nth-of-type(1) {
  font-size: 30px;
  font-weight: 600;
}
.framework_one span:nth-of-type(2) {
  font-size: 30px;
  font-weight: 600;
  color: #eddbcf;
}
.framework_two {
  width: 90%;
  height: 400px;
  /* outline: 1px solid red; */
}
.framework_two img {
  width: 100%;
  height: 400px;
}
/* 领导团队 */
.temer {
  width: 100%;
  /* height: 600px; */
  background-color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  /* outline: 1px solid red; */
}
.temer_one {
  width: 90%;
  height: 100px;
  /* margin-left: 20px; */
  /* margin-top: 10px; */
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: column;
}
.temer_one span:nth-of-type(1) {
  font-size: 30px;
  font-weight: 600;
}
.temer_one span:nth-of-type(2) {
  font-size: 30px;
  font-weight: 600;
  color: #eddbcf;
}
.temer_two {
  width: 100%;
  margin-bottom: 20px;
  padding-left: 100px;
  /* outline: 1px solid red; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
}
.temer_box {
  /* background-color: black; */
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-direction: column; */
}
.temer_box_img {
  /* width: 100px; */
  height: 110px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: aqua; */
  /* outline: 1px solid red; */
}
.temer_box_img img {
  width: 100%;
  height: 100%;
  /* background-color: #005bac; */
}
.item {
  padding: 5px 10px;
  width: 50%;
  height: 100px;
  background-color: #f1eff1;
  transition-duration: 0.3s;
  float: left;
  margin: 20px 20px 20px 10px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  /* outline: 1px solid red; */
}
.item h2 {
  font-size: 20px;
  font-weight: bold;
  line-height: 20px;
  border-left: 8px #005bac solid;
  padding-left: 15px;
  margin-bottom: 10px;
  transition-duration: 0.3s;
}

.item p {
  display: block;
  height: 300px;
  margin-top: 5px;
  /* padding-left: 20px; */
  line-height: 2;
  /* outline: 1px solid red; */
}

.item:hover {
  background-color: #ddd;
}

.item:hover h2 {
  border-left-color: #f39800;
}
/* 新闻动态 */
.newbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}
.main_new {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-top: 30px;
  margin-bottom: 30px;
  /* outline: 1px solid red; */
}
.new_one {
  width: 90%;
  height: 100px;
  /* margin-left: 20px; */
  /* margin-top: 10px; */
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-direction: column;
  /* outline: 1px solid red; */
}
.new_one span:nth-of-type(1) {
  font-size: 30px;
  font-weight: 600;
}
.new_one span:nth-of-type(2) {
  font-size: 30px;
  font-weight: 600;
  color: #eddbcf;
}
.news {
  width: 80%;
  height: 200px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* outline: 1px solid red; */
}
.news_left {
  width: 30%;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  /* outline: 1px solid red; */
}
.news_left img {
  width: 180px;
  height: 150px;
  line-height: 160px;
}
.news_left p {
  display: inline-block;
  width: 90%;
  height: 70%;
  text-align: center;
  background-color: aliceblue;
}
.news_right {
  width: 70%;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  /* outline: 1px solid red; */
}
.news_right h3 {
  width: 90%;
  height: 20%;
  text-align: left;
  padding: 10px;
  cursor: pointer;
  /* outline: 1px solid red; */
}
.news_right p {
  display: inline-block;
  width: 90%;
  height: 80%;
  text-align: left;
  word-wrap: break-word;
  padding: 10px;
}
.news_right p span {
  color: #f39800;
  margin-left: 10px;
  font-weight: bold;
  cursor: pointer;
}
</style>
