<template>
  <div id="app">
    <el-backtop :bottom="100">
      <div
        style="
           {
            height: 100%;
            width: 100%;
            background-color: #f2f5f6;
            box-shadow: 0 0 6px rgba(0, 0, 0, 0.12);
            text-align: center;
            line-height: 40px;
            color: #1989fa;
          }
        "
      >UP</div>
    </el-backtop>
    <NavPage />
    <!-- <router-view /> -->
  </div>
</template>
<script>
import NavPage from '@/components/NavPage.vue'
// import HomeView from '@/views/HomeView.vue'
export default {
  components: {
    NavPage
    // HomeView
  }
}
</script>

<style lang="less">
body {
  padding: 0;
  margin: 0;
}
/* 隐藏 Chrome、Safari 和 Opera 的滚动条 */
html::-webkit-scrollbar {
    display: none;
}

/* 为 IE、Edge 和 Firefox 隐藏滚动条 */
html {
  -ms-overflow-style: none;  /* IE 和 Edge */
  scrollbar-width: none;  /* Firefox */
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 0;
  padding: 0;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
