<template>
  <div class="container">
    <div class="top">
      <img src="../assets/page_banner.jpg" alt />
    </div>
    <div class="buttom">
      <el-tabs v-model="activeName" @tab-click="handleClick" :stretch="true">
        <el-tab-pane label="工业视频" name="industrialVideo">
          <ul class="industry">
            <li v-for="(item, index) in industry" :key="index">
              <div class="industry_img">
                <img :src="item.site.url + item.image" alt />
              </div>
              <div class="industry_text">
                <span @click="goToDetail(industry[index])">{{item.title}}</span>
                <p>
                  {{item.description}}
                  <span @click="goToDetail(industry[index])">[详情]</span>
                </p>
                <!-- <span>[详情]</span> -->
              </div>
            </li>
          </ul>
        </el-tab-pane>
        <el-tab-pane label="智能辅助" name="smartAssist">
          <ul class="industry">
            <li v-for="(item, index) in smartassist" :key="index">
              <div class="industry_img">
                <img :src="item.site.url + item.image" alt />
              </div>
              <div class="industry_text">
                <span @click="goToDetail(smartassist[index])">{{item.title}}</span>
                <p>
                  {{item.description}}
                  <span @click="goToDetail(smartassist[index])">[详情]</span>
                </p>
              </div>
            </li>
          </ul>
        </el-tab-pane>
        <el-tab-pane label="安全管控" name="securityControl">
          <ul class="industry">
            <li v-for="(item, index) in securityControl" :key="index">
              <div class="industry_img">
                <img :src="item.site.url + item.image" alt />
              </div>
              <div class="industry_text">
                <span @click="goToDetail(securityControl[index])">{{item.title}}</span>
                <p>
                  {{item.description}}
                  <span @click="goToDetail(securityControl[index])">[详情]</span>
                </p>
              </div>
            </li>
          </ul>
        </el-tab-pane>
        <el-tab-pane label="智慧能源" name="smartEnergy">
          <ul class="industry">
            <li v-for="(item, index) in smartEnergy" :key="index">
              <div class="industry_img">
                <img :src="item.site.url + item.image" alt />
              </div>
              <div class="industry_text">
                <span @click="goToDetail(smartEnergy[index])">{{item.title}}</span>
                <p>
                  {{item.description}}
                  <span @click="goToDetail(smartEnergy[index])">[详情]</span>
                </p>
              </div>
            </li>
          </ul>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import { dataSortByParam } from '@/utils/lls'
import { getArticle } from '@/api/index'
export default {
  data() {
    return {
      activeName: this.$route.params.name || 'industrialVideo',
      from: [],
      industry: [], // 工业视频
      smartassist: [], // 智能辅助
      securityControl: [], // 安全管控
      smartEnergy: [] // 智慧能源
    }
  },
  watch: {
    '$route.params.name'(to, from) {
      this.activeName = this.$route.params.name
    }
  },
  mounted() {
    this.inDustrialVideo()
    this.inSmarTassist()
    this.seCurityControl()
    this.smArtEnergy()
  },
  methods: {
    // 请求示例工业视频
    inDustrialVideo() {
      const param = {
        channel: 'industrialVideo'
      }
      getArticle(param).then(res => {
        this.industry = dataSortByParam(res.data, 'sticky')
        // console.log(this.industry, 'industry')
      })
    },
    // 请求示例智能辅助
    inSmarTassist() {
      const param = {
        channel: 'smartAssist'
      }
      getArticle(param).then(res => {
        this.smartassist = dataSortByParam(res.data, 'sticky')
        // console.log(this.smartassist, 'smartassist')
      })
    },
    // 请求安全管控
    seCurityControl() {
      const param = {
        channel: 'securityControl'
      }
      getArticle(param).then(res => {
        this.securityControl = dataSortByParam(res.data, 'sticky')
        // console.log(this.securityControl, 'securityControl')
      })
    },
    // 请求智慧能源
    smArtEnergy() {
      const param = {
        channel: 'smartEnergy'
      }
      getArticle(param).then(res => {
        this.smartEnergy = dataSortByParam(res.data, 'sticky')
        // console.log(this.smartEnergy, 'smartEnergy')
      })
    },
    goToDetail(item) {
      console.log(item)
      // 跳转
      this.$router.push({ name: 'plandetails', params: { data: item } })
    },
    handleClick(tab, event) {
      this.activeName = tab.name
    }
  }
}
</script>
<style scoped>
.container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
@media (min-width: 2000px) {
  .container {
    width: 100%; /* 在屏幕宽度大于或等于600px时，宽度设置为50% */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* outline: 1px solid red; */
  }
}
.top {
  width: 100%;
  height: 300px;
  overflow: hidden;
  position: relative;
}
.top img {
  width: 100%;
  height: 300px;
}
.top img:hover {
  transition: all 1.5s cubic-bezier(0.215, 0.61, 0.215, 1);
  transform: scale(1.1);
}
.buttom {
  width: 80%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-top: -80px;
  z-index: 999;
  background-color: white;
  margin-bottom: 30px;
  /* outline: 1px solid red; */
}
.el-tabs {
  width: 100%;
}
.el-tab-pane {
  width: 100%;
}
.el-tabs >>> .el-tabs__item {
  height: 80px;
  line-height: 80px;
}
/* 工业视频 */
.industry {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
}
.industry li {
  width: 30%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-right: 20px;
  /* box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1); */
}
.industry li:hover {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.industry li .industry_img {
  width: 100%;
  height: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* outline: 1px solid red; */
}
.industry_img img {
  width: 80%;
  height: 90%;
}
.industry_text {
  width: 100%;
  height: 40%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  /* outline: 1px solid red; */
}
.industry_text span {
  font-size: 18px;
  font-weight: 800;
  cursor: pointer;
}
.industry_text p {
  display: inline-block;
  font-size: 12px;
  width: 90%;
  height: 10%;
  text-overflow: ellipsis;
  text-align: left;
  word-wrap: break-word;
}
.industry_text p span {
  font-size: 12px;
  color: orange;
}
</style>
