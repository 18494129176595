<template>
  <div class="container">
    <div class="top">
      <img src="../assets/pagingBanner1.jpg" alt />
    </div>
    <div class="butom">
      <el-tabs v-model="activeName" @tab-click="handleClick" :stretch="true">
        <el-tab-pane label="企业理念" name="enterpriseMind">
          <div class="culture" v-if="linian.site">
            <div class="item-a">
              <div class="text">
                <div class="text_title">
                  <p></p>
                  <span>{{ linian.title }}</span>
                  <span>CORPORATE CULTURE</span>
                </div>
                <div class="text_cotent">
                  <div class="zzc"></div>
                  <!-- <p>文化建设</p> -->
                  <div v-html="linian.text"></div>
                </div>
              </div>
              <div class="image">
                <img :src="linian.site.url + linian.image" />
              </div>
            </div>
            <div class="item-b">
              <!-- <div class="item" v-for="(item, index) in build" :key="index">
                <h2>{{ item.title }}</h2>
                <div v-html="item.text"></div>
              </div>-->
              <div
                v-for="(item, index) in build"
                :key="index"
                :class="['item', showIndex === index ? 'item-active' : '']"
                @mouseenter="handleChangeCulture(index)"
              >
                <div class="img">{{ item.title }}</div>
                <div class="content">
                  <div class="title">{{ item.title }}</div>
                  <div class="text" v-html="item.text"></div>
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="企业风采" name="enterpriseStyle">
          <div class="news-list3" v-for="(item, index) in enterprisestyle" :key="index">
            <ul>
              <li>
                <div class="image">
                  <img :src="item.site.url + item.image" />
                </div>
                <div class="text">
                  <h2>{{ item.fullTitle }}</h2>
                  <div v-html="item.text"></div>
                </div>
              </li>
            </ul>
          </div>
        </el-tab-pane>
        <el-tab-pane label="企业伙伴" name="Partners">
          <div class="wrap-tit">
            <h2>
              <i class="i1"></i>
              <span>战略合作伙伴</span>
              <i class="i2"></i>
            </h2>
            <p>顾客至上 · 改革求实 · 以人为本 · 团结进取</p>
          </div>
          <div class="partner">
            <ul class="list">
              <li v-for="(item, index) in partner" :key="index">
                <img :src="item.site.url + item.image" />
              </li>
            </ul>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>
<script>
import { getArticle } from '@/api/index'
import { dataSortByParam } from '@/utils/lls'
export default {
  data() {
    return {
      activeName: this.$route.params.name || 'Partners',
      enterprisemind: [],
      enterprisestyle: [],
      partner: [],
      school: [],
      enterprise: [],
      build: [],
      linian: {},
      showIndex: 0
    }
  },
  watch: {
    '$route.params.name'(to, from) {
      this.activeName = this.$route.params.name
    }
  },
  mounted() {
    this.getPriseMind()
    this.getPriseStyle()
    this.busiNessPartners()
  },
  methods: {
    // 请求企业理念
    getPriseMind() {
      const param = {
        channel: 'enterpriseMind'
      }
      getArticle(param).then(res => {
        // console.log(res.data)
        this.enterprisemind = dataSortByParam(res.data, 'sticky')
        this.enterprisemind.forEach(item => {
          if (item.image) {
            this.linian = item
          } else {
            this.build.push(item)
          }
        })
      })
    },
    // 请求企业风采
    getPriseStyle() {
      const param = {
        channel: 'enterpriseStyle'
      }
      getArticle(param).then(res => {
        // console.log(res.data)
        this.enterprisestyle = dataSortByParam(res.data, 'sticky')
        // console.log(this.enterprisestyle, 'enterprisestyle')
      })
    },
    // 请求企业合作伙伴business
    busiNessPartners() {
      const param = {
        channel: 'businessPartners'
      }
      getArticle(param).then(res => {
        // console.log(res.data)
        this.partner = dataSortByParam(res.data, 'sticky')
        console.log(this.partner, 'partner')
      })
    },

    handleClick(tab, event) {
      this.activeName = tab.name
    },
    handleChangeCulture(index) {
      this.showIndex = index
    }
  }
}
</script>
<style scoped>
.container {
  width: 100%;
  /* min-height: 800px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

@media (min-width: 2000px) {
  .container {
    width: 100%;
    /* 在屏幕宽度大于或等于600px时，宽度设置为50% */
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

.top {
  width: 100%;
  height: 300px;
  overflow: hidden;
  position: relative;
}

.top img {
  width: 100%;
  height: 300px;
}

.top img:hover {
  transition: all 1.5s cubic-bezier(0.215, 0.61, 0.215, 1);
  transform: scale(1.1);
}

.butom {
  width: 90%;
  /* min-height: 800px; */
  height: auto;
  background-color: white;
  z-index: 999;
  margin-bottom: 30px;
  margin-top: -80px;
  /* outline: 1px solid red; */
}

.el-tabs >>> .el-tabs__item {
  height: 80px;
  line-height: 80px;
}

/* 企业理念 */
.culture {
  width: 100%;
  /* height: 600px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  /* outline: 1px solid red; */
}

.culture .item-a {
  width: 80%;
  height: 400px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  /* outline: 1px solid red; */
}

.culture .item-a .image {
  width: 50%;
  height: 320px;
  position: absolute;
  right: 0;
  top: 60px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  /* padding: 10px; */
  /* outline: 1px solid red; */
}

.culture .item-a .image img {
  width: 100%;
  height: 100%;
}

.culture .item-a .text {
  width: 70%;
  height: 400px;
  z-index: 999;
  /* outline: 1px solid red; */
}
.text_title {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* outline: 1px solid red; */
}
.text_title p {
  display: inline-block;
  width: 10px;
  height: 30px;
  background-color: #1d50af;
}
.text_title span:nth-of-type(1) {
  display: inline-block;
  width: 120px;
  font-size: 26px;
  font-weight: 900;
  /* outline: 1px solid red; */
}
.text_title span:nth-of-type(2) {
  display: inline-block;
  width: 200px;
  height: 30px;
  line-height: 45px;
  font-size: 16px;
  /* outline: 1px solid red; */
}
.text_cotent {
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  opacity: 1;
  position: relative;
  /* overflow: hidden; */
  background-color: rgba(237, 235, 235, 0.6);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  /* outline: 1px solid red; */
}

.text_cotent div {
  width: 100%;
  height: 250px;
  color: black;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.zzc {
  width: 100%;
  height: 250px;
  position: absolute;
  inset: 0px;
  opacity: 0;
  /* z-index: 1000; */
  transition: all 0.3s linear;
  background-color: rgba(0, 0, 0, 0.5);
}
.text_cotent:hover .zzc {
  opacity: 1;
}
.text_cotent p {
  display: inline-block;
  /* outline: 1px solid red; */
  position: absolute;
  top: 60px;
  left: 560px;
  font-size: 40px;
  font-weight: 900;
  color: white;
  opacity: 0;
  transition: all 0.4s 0.3s;
}
.text_cotent:hover p {
  opacity: 1;
  transform: translateY(-10px);
}
/* 企业理念1.0 */
/* .item-b {
  width: 80%;
  height: 300px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: row;
}
.culture .item-b .item {
  background-color: #f2f2f2;
  width: 25%;
  float: left;
  height: 220px;
  padding: 20px 40px;
  text-align: center;
}
.culture .item-b .item h2 {
  font-size: 18px;
  margin-top: 15px;
}
.culture .item-b .item div {
  line-height: 2;
  margin-top: 10px;
  color: #6a6a6a;
}
.culture .item-b .item i {
  font-size: 65px;
  color: #8a8a8a;
  display: block;
  margin: 0 auto;
  width: 50px;
}
.culture .item-b .item.s2 {
  background-color: #fafafa;
} */
/* 企业理念2.0 */
.item-b {
  width: 80%;
  margin: 30px auto;
  height: 200px;
  /* outline: 1px solid red; */
  display: flex;
}

.item {
  flex: 1;
  height: 100%;
  transition: flex 0.3s ease 0s;
  margin-right: 20px;
  /* border-radius: 20px; */
  position: relative;
}

.item-active {
  flex: 2;
  transition: flex 0.3s ease 0s;
}

.item .img {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 1;
  background-color: #1d50af;
  color: #fff;
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  transition: opacity 0.5s ease 0s;
}

.item-active .img {
  opacity: 0;
  transition: opacity 0.5s ease 0s;
}

.item .img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.item .content {
  opacity: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: opacity 0.2s ease-out 0s;
}

.item-active .content {
  opacity: 1;
  transition: opacity 0.5s ease-in 0s;
}

.item .content .text {
  flex: 2;
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 2;
  margin-top: 10px;
  font-size: 16px;
  color: #6a6a6a;
}

.item .content .title {
  font-size: 16px;
  flex: 1;
  width: 80%;
  display: flex;
  justify-content: start;
  align-items: end;
  font-weight: bolder;
}

/* --------------企业风采---------------------- */
.news-list3 {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.news-list3 ul li {
  background-color: #f1eff1;
  margin-bottom: 30px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.news-list3 ul li .image {
  width: 300px;
  height: 200px;
  float: left;
  position: relative;
  overflow: hidden;
}

.news-list3 ul li .image img {
  width: 300px;
  height: 200px;
  transition: all 1s;
  -webkit-transition: all 1s;
}

.news-list3 ul li .text {
  width: 650px;
  float: right;
  padding: 35px 40px 0;
}

.news-list3 ul li .text h2 {
  padding-bottom: 15px;
  border-bottom: 1px #e2e2e2 solid;
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: bold;
}

.news-list3 ul li .text h2 em {
  color: #f39800;
  margin-left: 10px;
  font-size: 14px;
}

.news-list3 ul li .text div {
  line-height: 2;
  font-size: 13px;
  color: #7a7a7a;
}

.news-list3 ul li .text a.more {
  display: block;
  width: 120px;
  background-color: #f39800;
  color: #fff;
  text-align: center;
  line-height: 40px;
  margin-top: 20px;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  visibility: hidden;
  filter: alpha(opacity=0);
  opacity: 0;
  margin-left: 20px;
}

.news-list3 ul li:hover .text a.more {
  visibility: visible;
  filter: alpha(opacity=100);
  opacity: 1;
  margin-left: 0;
}

.news-list3 ul li:hover .image img {
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
}

.partner {
  width: 100%;
}

/* 选项卡设置 */
/* .el-tabs2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
} */

.list {
  width: 100%;
  background-color: #5a5a5a;
  list-style: none;
}

.list li {
  width: 200px;
  height: 120px;
  float: left;
  border: 1px #ddd solid;
  margin-right: 10px;
  margin-bottom: 30px;
  box-shadow: 5px 5px 0px 0px rgba(0, 0, 0, 0.03);
}

.list li img {
  width: 200px;
  height: 120px;
}

.list li span {
  display: block;
  line-height: 100px;
  text-align: center;
  font-size: 32px;
  color: #aaa;
}

.list li.hover {
  opacity: 1;
}
</style>
