<template>
  <div class="hello">
    <ComPany :carouselList="carouselList" />
    <transition name="slide-right">
      <div v-if="showImage" class="big_box">
        <!-- 走进力隆升 -->
        <div class="lls">
          <div class="wrap-tit">
            <h2>走进力隆升</h2>
            <p>Entering Lilongsheng</p>
          </div>
          <div class="wrap_bot">
            <div class="wrap_bot_img">
              <span>关于我们</span>
              <img v-if="showImage" src="../assets/llsqiantai.jpg" alt />
            </div>
            <div class="wrap_bot_right">
              <el-tabs class="lls_eltabs" v-model="activeName">
                <el-tab-pane label="核心产品" name="products">
                  <el-scrollbar class="product-container" :native="false">
                    <div
                      class="product-item"
                      @click="handleProduct(index)"
                      v-for="(item, index) in productList"
                      :key="item.id"
                    >
                      <div class="product-item-title">{{ item.title }}</div>
                      <div class="product-item-tag">{{ item.tags[0] ? item.tags[0].name : '' }}</div>
                    </div>
                  </el-scrollbar>
                </el-tab-pane>
                <el-tab-pane label="成功案例" name="projectCase">
                  <el-scrollbar class="project-container" :native="false">
                    <div
                      class="project-item"
                      @click="handleProject(index)"
                      v-for="(item, index) in projectCaseList"
                      :key="item.id"
                    >
                      <div class="project-item-title">{{ item.title }}</div>
                      <div class="project-item-tag">{{ item.tags[0] ? item.tags[0].name : '' }}</div>
                    </div>
                  </el-scrollbar>
                </el-tab-pane>
                <el-tab-pane label="新闻资讯" name="news">
                  <el-scrollbar class="project-container" :native="false">
                    <div
                      class="project-item"
                      @click="handleNews(index)"
                      v-for="(item, index) in news"
                      :key="item.id"
                    >
                      <div class="project-item-title">{{ item.title }}</div>
                      <div class="project-item-tag">{{ item.tags[0] ? item.tags[0].name : '' }}</div>
                    </div>
                  </el-scrollbar>
                </el-tab-pane>
              </el-tabs>
            </div>
          </div>
        </div>
        <!-- adv 核心业务-->
        <div class="adv">
          <div class="content">
            <div class="wrap-tit wow">
              <h2>核心业务</h2>
              <p>满足工程品质 · 精算师严控预算 · 超前布局满足未来长远发展</p>
            </div>
            <div class="adv-con">
              <div class="adv-le">
                <template v-for="(item, index) in coreBusinessList">
                  <div
                    v-if="index < 2"
                    :class="['item', 'wow', 'box' + (index + 1)]"
                    :key="item.id"
                  >
                    <h2>{{ item.title }}</h2>
                    <div v-html="item.text"></div>
                  </div>
                </template>
              </div>
              <div class="adv-cn">
                <span>
                  <img src="../assets/hexinyewu/adv01.png" alt />
                </span>
                <img src="../assets/hexinyewu/adv02.png" alt />
              </div>
              <div class="adv-ri">
                <template v-for="(item, index) in coreBusinessList">
                  <div
                    v-if="index > 1"
                    :class="['item', 'wow', 'box' + (index + 1)]"
                    :key="item.id"
                  >
                    <h2>{{ item.title }}</h2>
                    <div v-html="item.text"></div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
        <!-- server 高品质-->
        <div
          class="server"
          v-if="strengthList.length > 0"
          @mouseenter="stopServer"
          @mouseleave="setServerInterval"
          id="block3"
        >
          <div class="server-cover">
            <div class="server-cover-img"></div>
          </div>
          <div
            class="server-backimg"
            :style="'background-image: url(' + strengthList[serverIndex].site.url + strengthList[serverIndex].image + ')'"
          ></div>
          <div class="server-container">
            <div class="wrap-tit">
              <h2>
                专注高品质
                <span>智能化系统</span>
              </h2>
              <p>先进技术与施工设备更擅长高品质工程</p>
            </div>
            <div class="server-con">
              <!-- <div class="item" v-for="item in strengthList" :key="item.id">
                <img :src="item.site.url + item.image" :alt="item.title" />
                <h2>{{ item.title }}</h2>
                <span>{{ item.subtitle }}</span>
                <div v-html="item.text"></div>
                <i></i>
              </div>-->
              <div
                @click="handleServer(index)"
                :class="['server-item', serverIndex === index ? 'server-item-active': '']"
                v-for="(item, index) in strengthList"
                :key="item.id"
                :style="'margin-left: ' + ((-30 + (7 * (strengthList.length - 3))) * index) + 'px'"
              >
                <div class="server-item-left">{{ String.fromCharCode(65 + index) }}</div>
                <div class="server-item-content">
                  <div class="server-title">{{ item.title }}</div>
                  <div class="server-text" v-html="item.text"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- server end -->
        <!-- process 服务流程-->
        <div class="process">
          <div class="wrap-tit">
            <h2>标准化服务流程</h2>
            <p>Do you have these concerns</p>
          </div>
          <div class="process-con">
            <div class="item">
              <img src="../assets/liucheng/goutong.png" alt="需求沟通" />
              <p>需求沟通</p>
            </div>
            <div class="item">
              <img src="../assets/liucheng/xianchang.png" alt="现场勘查" />
              <p>现场勘查</p>
            </div>
            <div class="item">
              <img src="../assets/liucheng/jiance-fangan.png" alt="定制方案" />
              <p>定制方案</p>
            </div>
            <div class="item">
              <img src="../assets/liucheng/shigongdi.png" alt="高效施工" />
              <p>高效施工</p>
            </div>
            <div class="item">
              <img src="../assets/liucheng/yunxintiaoshi.png" alt="试运行&调试" />
              <p>试运行&调试</p>
            </div>
            <div class="item">
              <img src="../assets/liucheng/jianyan-yanshou.png" alt="设备验收" />
              <p>设备验收</p>
            </div>
            <div class="item">
              <img src="../assets/liucheng/jiaofufuwu.png" alt="交付培训" />
              <p>交付培训</p>
            </div>
            <div class="item">
              <img src="../assets/liucheng/shouhou.png" alt="售后维保" />
              <p>售后维保</p>
            </div>
          </div>
        </div>
        <!-- 滚动 -->
        <div class="scroll">
          <div class="kongbai">
            <el-tabs class="honor_partner" v-model="honorPartner" :stretch="true">
              <el-tab-pane class="honor_partner_one" label="荣誉资质" name="honor">
                <div class="honor_one" v-for="(item, index) in honorList" :key="item.id">
                  <el-image
                    :src="item.site.url + item.image"
                    :preview-src-list="srcList"
                    @click="previewImg(index)"
                    :initial-index="initialIndex"
                    fit="contain"
                  ></el-image>
                  <span>{{ item.title }}</span>
                </div>
              </el-tab-pane>
              <el-tab-pane class="honor_partner_two" label="合作单位" name="partner">
                <el-scrollbar class="project-container" :native="false">
                  <div class="partner_one" v-for="item in partnerList" :key="item.id">
                    <h3>
                      <a
                        :href="item.linkUrl"
                        :target="item.targetBlank ? '_blank' : '_self'"
                      >{{ item.title }}</a>
                    </h3>
                  </div>
                </el-scrollbar>
              </el-tab-pane>
            </el-tabs>
          </div>
          <div class="aboutus">
            <el-tabs class="aboutus_tab" type="card" value="wewe">
              <el-tab-pane label="slot" name="wewe">
                <span slot="label">
                  <i class="el-icon-s-custom"></i> 关于我们
                </span>
              </el-tab-pane>
            </el-tabs>
            <div class="container">
              <div class="wrapper">
                <template v-for="(item, index) in enterpriseStyleList">
                  <div
                    @mouseenter="stopScroll"
                    @mouseleave="startScroll"
                    :key="index"
                    class="wrapper-item"
                    ref="container"
                    :style="{ transform: 'translateX(' + translateX + 'px)' }"
                  >
                    <div class="image-wrapper">
                      <img :src="item.site.url + item.image" class="image" />
                    </div>
                    <div class="text-wrapper">
                      <span class="item-title">{{ item.title }}</span>
                    </div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { getArticle } from '@/api/index'
import ComPany from '@/components/ComPany.vue'
import { dataSortByParam } from '@/utils/lls'
export default {
  name: 'AboutView',
  components: {
    ComPany
  },
  props: {
    msg: String
  },
  data() {
    return {
      fits: ['fill', 'contain', 'cover', 'none', 'scale-down'],
      carouselList: [], // 轮播图图片
      projectCaseList: [], // 工程案例
      productList: [], // 产品服务
      news: [], // 新闻资讯
      coreBusinessList: [], // 核心业务
      strengthList: [], // 服务优势
      honorList: [], // 荣誉资质
      partnerList: [], // 合作单位
      enterpriseStyleList: [], // 企业风采
      enterpriseStyleListLength: 0,
      activeName: 'products',
      honorPartner: 'honor',
      srcList: [],
      showImage: true, // 图片划入
      scrollSpeed: 25, // 滚动速度
      containerWidth: 0,
      imageWidth: 210, // 图片宽度
      translateX: 0,
      scrollInterval: null,
      initialIndex: 0, // 当前图片预览index
      serverSpeed: 4000,
      serverInterval: null,
      serverIndex: 0
    }
  },
  mounted() {
    this.getCarousel()
    this.getProjectCase()
    this.getProducts()
    this.getCoreBusiness()
    this.getStrengths()
    this.getHonors()
    this.getPartners()
    this.getEnterpriseStyle()
    this.getNews()
  },
  unmounted() {
    clearInterval(this.scrollInterval)
    clearInterval(this.serverInterval)
  },
  methods: {
    // 获取轮播图
    getCarousel() {
      getArticle({ channel: 'carousel' }).then(res => {
        this.carouselList = dataSortByParam(res.data, 'sticky')
      })
    },
    // 获取工程案例
    getProjectCase() {
      getArticle({ channel: 'projectCase' }).then(res => {
        this.projectCaseList = dataSortByParam(res.data, 'sticky')
      })
    },
    // 获取产品服务
    getProducts() {
      getArticle({ channel: 'products' }).then(res => {
        this.productList = dataSortByParam(res.data, 'sticky')
      })
    },
    // 获取新闻资讯
    getNews() {
      getArticle({ channel: 'news' }).then(res => {
        this.news = res.data
        // console.log(this.news, 'news')
      })
    },
    // 获取核心业务
    getCoreBusiness() {
      getArticle({ channel: 'coreBusiness' }).then(res => {
        this.coreBusinessList = dataSortByParam(res.data, 'sticky')
      })
    },
    // 获取服务优势
    getStrengths() {
      getArticle({ channel: 'strengths' }).then(res => {
        const that = this
        this.strengthList = res.data
        this.$nextTick(() => {
          that.setServerInterval()
        })
      })
    },
    // 获取荣誉资质
    getHonors() {
      getArticle({ channel: 'mainCert' }).then(res => {
        this.honorList = dataSortByParam(res.data, 'sticky')
        if (this.srcList.length === 0) {
          for (const item of this.honorList) {
            this.srcList.push(item.site.url + item.image)
          }
        }
      })
    },
    previewImg(index) {
      this.initialIndex = index
    },
    // 获取合作单位
    getPartners() {
      getArticle({ channel: 'mainPartners' }).then(res => {
        this.partnerList = dataSortByParam(res.data, 'sticky')
      })
    },
    // 获取企业风采
    getEnterpriseStyle() {
      getArticle({ channel: 'enterpriseStyle' }).then(res => {
        const styleList = []
        this.enterpriseStyleListLength = res.data.length
        let copyNum = 2
        if (this.enterpriseStyleListLength <= 4) {
          copyNum = 3
        }
        while (copyNum) {
          copyNum--
          styleList.push(...res.data)
        }
        this.enterpriseStyleList = styleList
        const that = this
        this.$nextTick(() => {
          that.containerWidth = that.$refs.container.offsetWidth
          that.startScroll()
        })
      })
    },
    handleProject(index) {
      this.$router.push({ name: 'projectdetails', params: { data: this.projectCaseList[index] } })
    },
    handleProduct(index) {
      this.$router.push({ name: 'projectdetails', params: { data: this.productList[index] } })
    },
    handleNews(index) {
      this.$router.push({ name: 'newdetaile', params: { data: this.news[index] } })
    },
    startScroll() {
      this.scrollInterval = setInterval(() => {
        const currentTranslateX = Math.abs(this.translateX)
        if (currentTranslateX >= this.imageWidth * this.enterpriseStyleListLength) {
          this.translateX = 0
        }
        this.translateX -= 1
      }, this.scrollSpeed)
    },
    stopScroll() {
      clearInterval(this.scrollInterval)
    },
    setServerInterval() {
      this.serverInterval = setInterval(() => {
        this.serverIndex === this.strengthList.length - 1 ? (this.serverIndex = 0) : this.serverIndex++
      }, this.serverSpeed)
    },
    stopServer() {
      clearInterval(this.serverInterval)
    },
    handleServer(index) {
      this.serverIndex = index
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
/deep/ p {
  padding: 0;
  margin: 0;
}
/* main部分 */
.hello {
  display: flex;
  // justify-content: center;
  // align-items: center;
  flex-direction: column;
  // transition: opacity 0.3s ease;
  // transition: opacity 0.5s;
}

@media (min-width: 2000px) {
  .hello {
    width: 100%;
    /* 在屏幕宽度大于或等于600px时，宽度设置为50% */
    display: flex;
    // justify-content: center;
    // align-items: center;
    flex-direction: column;
  }
}
/* 定义进入动画 */
.slide-right-enter-active {
  transition: all 0.5s ease;
}
.slide-right-enter {
  transform: translateX(100%);
}
.big_box {
  widows: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

// 走进力隆升
.lls {
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  margin-top: 80px;
  margin-bottom: 80px;
  transition: opacity 0.5s;
  //   outline: 1px solid red;
}

@media (min-width: 2000px) {
  .lls {
    width: 60%;
    // height: 500px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
    //   outline: 1px solid red;
  }
  .wrap_bot_img {
    width: 40%;
    height: 360px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    // outline: 1px solid red;
  }
  .wrap_bot_img img {
    width: 20%;
    height: 360px;
    border-radius: 10px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
    // outline: 1px solid red;
  }
  .wrap_bot_img span {
    display: inline-block;
    padding: 20px;
    background-color: #5d94fb;
    position: absolute;
    left: 80px;
    // outline: 1px solid red;
  }
}

.wrap_bot {
  width: 95%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #5f92fc;
  // box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5), 0 0 6px rgba(0, 0, 0, 0.3);
}

.wrap_bot_img {
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  // outline: 1px solid red;
}
.wrap_bot_img img {
  width: 80%;
  height: 360px;
  border-radius: 10px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.3);
}
.wrap_bot_img span {
  display: inline-block;
  padding: 20px;
  background-color: #5d94fb;
  position: absolute;
  left: 50px;
  // outline: 1px solid red;
}

.wrap_bot_right {
  // background-color: #282a89;
  width: 60%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.lls_eltabs {
  width: 80%;
  height: 100%;
}

.project-container,
.product-container {
  height: 205px;
  width: 100%;
}

/deep/ .el-scrollbar__wrap {
  overflow-x: hidden;
}

.project-item,
.product-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 5px 5px 0;
  font-size: 14px;
  color: #656565;
  cursor: pointer;
}

.project-item:hover,
.product-item:hover {
  background-color: rgba(135, 206, 250, 0.5);
}

.adv {
  width: 95%;
  // height: 800px;
  position: relative;
  overflow: hidden;
  // padding-top: 80px;
  padding-bottom: 100px;
  // outline: 1px solid red;
}

// @media only screen and (max-width: 1200px) {
//   body {
//     padding: 10px;
//   }
// }
@media (min-width: 2000px) {
  .adv {
    width: 60%;
    // height: 500px;
    /* 在屏幕宽度大于或等于600px时，宽度设置为50% */
  }
//   .adv-con .adv-cn > span img {
//     width: 95%;
//     height: 320px;
//   }
//   .adv-con {
//     width: 100%;
//     margin-top: 80px;
//     overflow: hidden;
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     outline: 1px solid red;
//   }
//   .adv-con .adv-cn > img {
//   // outline: 1px solid red;
//   width: 320px;
//   height: 320px;
//   position: absolute;
//   left: 155px;
//   top: -10px;
//   animation: light2 7s linear infinite;
// }
}

.adv-con {
  width: 100%;
  // height: 320px;
  // margin-top: 80px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // outline: 1px solid red;
}

.adv-con .adv-le {
  float: left;
  width: 30%;
  text-align: right;
  // outline: 1px solid red;
}

.adv-con .item {
  position: relative;
}

.adv-con .box1:after {
  position: absolute;
  right: -121px;
  top: 45px;
  content: '';
  display: block;
  background: url('../assets/hexinyewu/adv-icon01.png') no-repeat center;
  width: 213px;
  height: 47px;
}

.adv-con .box2:after {
  position: absolute;
  right: -123px;
  top: 4px;
  content: '';
  display: block;
  background: url('../assets/hexinyewu/adv-icon02.png') no-repeat center;
  width: 262px;
  height: 46px;
}

.adv-con .item h2 {
  font-size: 24px;
  margin-bottom: 35px;
}

.adv-con .item p {
  line-height: 1.75;
  font-size: 14px;
  color: #1a1a1a;
}

.adv-con .box2 {
  margin-top: 155px;
  margin-right: 110px;
}

.adv-con .box1 h2 {
  color: #282a89;
}

.adv-con .box2 h2 {
  color: #ed1b23;
}

.adv-con .adv-cn {
  // margin-top: 37px;
  float: left;
  width: 40%;
  height: 300px;
  position: relative;
  // outline: 1px solid red;
}

.adv-con .adv-cn:after {
  content: '';
  display: block;
  position: absolute;
  bottom: -21px;
  left: 50%;
  background: url('../assets/hexinyewu/adv-shaow.png') no-repeat center;
  width: 156px;
  height: 21px;
  transform: translateX(-50%);
}

.adv-con .adv-cn > span {
  display: block;
  width: 100%;
  font-size: 0;
}

.adv-con .adv-cn > span img {
  width: 100%;
  height: 320px;
}

.adv-con .adv-cn > img {
  // outline: 1px solid red;
  width: 320px;
  height: auto;
  position: absolute;
  left: 153px;
  top: -10px;
  animation: light2 7s linear infinite;
}

.adv-con .adv-ri {
  float: right;
  width: 30%;
  text-align: left;
  // outline: 1px solid red;
}

.adv-con .box3 {
  margin-top: 100px;
  width: 88%;
  margin-left: 12%;
}

.adv-con .box4 {
  margin-top: 60px;
  width: 88%;
  margin-left: 12%;
  // outline: 1px solid red;
}

.adv-con .box3 h2 {
  color: #ed1b23;
}

.adv-con .box4 h2 {
  color: #282a89;
}

.adv-con .box3:after {
  position: absolute;
  left: -80px;
  top: 30px;
  content: '';
  display: block;
  background: url('../assets/hexinyewu/adv-icon03.png') no-repeat center;
  width: 200px;
  height: 60px;
}

.adv-con .box4:after {
  position: absolute;
  left: -140px;
  top: 30px;
  content: '';
  display: block;
  background: url('../assets/hexinyewu/adv-icon04.png') no-repeat center;
  width: 240px;
  height: 50px;
}

.adv-con .adv-le .item {
  animation: fadeinL 1s ease-out backwards;
}

.adv-con .adv-ri .item {
  animation: fadeinR 1s ease-out backwards;
}

@keyframes light2 {
  0% {
    transform: rotateZ(0deg) rotateY(0deg);
  }

  50% {
    transform: rotateZ(180deg) rotateY(0deg);
  }

  100% {
    transform: rotateZ(360deg) rotateY(0deg);
  }
}

/* server */
.server {
  // overflow: hidden;
  // background: url('../assets/zhuanzhubanner.jpg') no-repeat center;
  width: 100%;
  // min-height: 550px;
  background-size: cover;
  margin-top: 80px;
  margin-bottom: 100px;
  position: relative;
  transition: all 0.4s ease 0s;
}

@media (min-width: 2000px) {
  .server {
    width: 60%;
    /* 在屏幕宽度大于或等于600px时，宽度设置为50% */
  }
}
.server-cover {
  position: absolute;
  top: 0;
  right: 0;
  width: 58%;
  height: 100%;
  clip-path: polygon(0 100%, 100% 100%, 100% 0, 14% 0);
}
.server-cover-img {
  height: 100%;
  width: 100%;
  background: url('../assets/zhuanzhubanner.jpg') no-repeat center;
  opacity: 0.1;
}
.server-backimg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 100%;
  width: 50.2%;
  position: absolute;
  top: 0;
  left: 0;
  clip-path: polygon(0 100%, 83.7% 100%, 100% 0, 0 0);
  transition: all 0.8s ease 0s;
}
.server-container {
  float: right;
  width: 100%;
  // height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.server .wrap-tit {
  z-index: 2;
}
.wrap-tit h2 {
  width: 100%;
  // height: 120px;
  font-size: 30px;
}

.server .wrap-tit h2 {
  color: black;
}

.server .wrap-tit h2 span {
  color: #1d50af;
}

.server .wrap-tit p {
  color: black;
}

.server-con {
  margin-top: 60px;
  transform: translateX(35%);
  width: 50%;
  display: flex;
  flex-direction: column;
  margin-left: 65px;
  // outline: 1px solid rgb(38, 0, 255);
}

.server-item {
  width: 100%;
  display: flex;
  // outline: 1px solid red;
  margin-bottom: 35px;
}
.server-item-active .server-item-left {
  background-color: #102c5f;
  transition: all 0.8s ease 0s;
}
.server-item-left {
  cursor: pointer;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #1d50af;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bolder;
  color: #fff;
  transition: all 0.4s ease 0s;
}
.server-item-content {
  flex: 1;
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.server-title {
  font-size: 18px;
  font-weight: bolder;
  text-align: left;
  color: #333;
}
.server-text {
  font-size: 16px;
  text-align: left;
}
// .server-con .item {
//   position: relative;
//   float: left;
//   transition: all 1s;
//   width: 350px;
//   height: 545px;
//   margin-right: 40px;
//   background: #fff;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   flex-direction: column;
// }

// .server-con .item:hover {
//   transform: translateY(-30px);
// }

// .server-con .item:last-of-type {
//   margin-right: 0;
// }

// .server-con .item img {
//   width: 320px;
//   // background-color: #ed1b23;
// }

// .server-con .item h2 {
//   width: 95%;
//   display: flex;
//   font-size: 26px;
//   color: #333;
// }

// .server-con .item span {
//   width: 95%;
//   display: flex;
//   font-size: 24px;
//   color: #333;
// }

// .server-con .item div {
//   font-size: 14px;
//   color: #333;
//   line-height: 1.5;
//   width: 90%;
// }

// .server-con .item i {
//   position: absolute;
//   left: 17px;
//   bottom: 22px;
//   display: block;
//   width: 20px;
//   height: 4px;
//   background: #ed1b23;
// }

/* server end*/

/* process */
.process {
  width: 95%;
  // margin-top: 30px;
  margin-bottom: 30px;
  overflow: hidden;
  padding: 50px 0 80px 0;
  background: #f6f6f6;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

@media (min-width: 2000px) {
  .process {
    width: 60%;
    /* 在屏幕宽度大于或等于600px时，宽度设置为50% */
  }
}

.process-con {
  width: 100%;
  // height: 300px;
  margin-top: 60px;
  // overflow: hidden;
  text-align: center;
  // outline: 1px solid red;
}

.process-con:after {
  content: '';
  display: block;
  margin-top: 62px;
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #666;
  box-sizing: border-box;
}

.process-con .item {
  width: 100px;
  height: 100px;
  margin-left: 40px;
  margin-right: 40px;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.process-con .item img {
  width: 60px;
  margin-top: 28px;
  object-fit: cover;
  //   outline: 1px solid red;
}

.process-con .item p {
  font-size: 17px;
  color: black;
  margin-top: 5px;
}

.process-con .item:nth-of-type(odd) {
  animation: fadeinL 1s ease-out backwards;
}

.process-con .item:nth-of-type(even) {
  animation: fadeinL 2s ease-out backwards;
}

.process-con .item:last-of-type {
  margin-right: 0;
}

/* process end */
// 图片滚动
.scroll {
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 30px;
  margin-bottom: 30px;
}
.kongbai,
.aboutus {
  flex: 1;
}
.kongbai {
  // width: 50%;
  height: inherit;
  display: flex;
  justify-content: center;
  align-content: center;
}
.honor_partner {
  width: 80%;
  height: inherit;
  padding: 0;
}

.honor_partner_one {
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: center;
}

.honor_one {
  width: 20%;
  height: 120px;
  display: flex;
  justify-content: space-between;
  align-content: center;
  flex-direction: column;
  margin-top: 0;
  margin-left: 5px;
  margin-right: 5px;
  padding: 5px;
}

.honor_one .el-image {
  width: 100%;
  height: 100px;
}

.honor_one span {
  font-size: 12px;
  color: #1a1a1a;
}

.honor_partner_two {
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.partner_one {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
}

.partner_one a {
  text-decoration: none;
  color: inherit;
}

.aboutus {
  width: 0;
  height: 100%;
}

.container {
  width: 100%;
  height: 70%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

@media (min-width: 2000px) {
  .scroll {
    width: 60%;
    /* 在屏幕宽度大于或等于600px时，宽度设置为50% */
  }

  .kongbai {
    width: 50%;
    height: inherit;
    // background-color: #666;
  }

  .container {
    width: 100%;
    display: flex;
    margin-bottom: 20px;
  }
}

.wrapper {
  width: 90%;
  display: flex;
  height: 100%;
  overflow: hidden;
}

.wrapper-item {
  display: flex;
  flex-direction: column;
  margin-right: 10px;
}

.image-wrapper,
.text-wrapper {
  flex: 1;
}

.text-wrapper {
  font-size: 14px;
  color: #979797;
}

.image {
  width: 200px;
  /* 图片宽度 */
  height: 150px;
  /* 图片高度 */
}

.item-title {
  margin-right: 20px;
  /* 标题之间的间距 */
}
</style>
