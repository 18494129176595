<template>
  <div>
    <el-container style="display: flex; flex-direction: column" v-if="siteInfo.url">
      <el-header height="100px">
        <div class="nav">
          <div class="nav_left">
            <img :src="siteInfo.url + siteInfo.logo" alt />
          </div>
          <div class="nav_right">
            <el-menu
              router
              class="el-menu-demo"
              mode="horizontal"
              @select="handleSelect"
              background-color="#545c64"
              text-color="#fff"
              active-text-color="#ffd04b"
              :default-active="$route.path"
            >
              <el-menu-item class="about" index="/">首页</el-menu-item>
              <el-submenu index="submenu1" v-show="isShowSubMenu">
                <template slot="title">公司概况</template>
                <el-menu-item index="/introduction/company">公司简介</el-menu-item>
                <el-menu-item index="/qualification/certificate">资质荣誉</el-menu-item>
                <el-menu-item index="/framework/architecture">组织架构</el-menu-item>
                <el-menu-item index="/teamer/team">领导团队</el-menu-item>
                <el-menu-item index="/news/news">新闻资讯</el-menu-item>
              </el-submenu>
              <el-submenu index="submenu2" v-show="isShowSubMenu">
                <template slot="title">解决方案</template>
                <el-menu-item index="/industrialVideo/industrialVideo">工业视频</el-menu-item>
                <el-menu-item index="/smartAssist/smartAssist">智能辅助</el-menu-item>
                <el-menu-item index="/securityControl/securityControl">安全管控</el-menu-item>
                <el-menu-item index="/smartEnergy/smartEnergy">智慧能源</el-menu-item>
              </el-submenu>
              <el-submenu index="projectCase" v-show="isShowSubMenu">
                <template slot="title">工程案例</template>
                <el-menu-item index="/substationProject/substationProject">输变电工程项目</el-menu-item>
                <el-menu-item index="/hydropowerProject/hydropowerProject">水利水电项目</el-menu-item>
                <el-menu-item index="/trafficProject/trafficProject">轨道交通项目</el-menu-item>
                <el-menu-item index="/industrialService/industrialService">工业服务</el-menu-item>
              </el-submenu>
              <el-submenu index="submenu4" v-show="isShowSubMenu">
                <template slot="title">企业文化</template>
                <el-menu-item index="/enterpriseMind/enterpriseMind">企业理念</el-menu-item>
                <el-menu-item index="/enterpriseStyle/enterpriseStyle">企业风采</el-menu-item>
                <el-menu-item index="/partners/Partners">企业伙伴</el-menu-item>
              </el-submenu>
              <el-menu-item class="about" index="/contactus">联系我们</el-menu-item>
            </el-menu>
          </div>
        </div>
      </el-header>
      <el-main>
        <router-view />
      </el-main>
      <el-footer height="300px">
        <div
          class="footer"
          :style="'background-image:url(' + (siteInfo.url + siteInfo.customs.footer_img)+ ')'
"
        >
          <div class="footer-left">
            <div class="footer-left_top">
              <router-link to="/" style="color:#ffffff;text-decoration: none;">首页</router-link>&nbsp;▪&nbsp;
              <router-link
                to="/introduction/company"
                style="color:#ffffff;text-decoration: none;"
              >公司概况</router-link>&nbsp;▪&nbsp;
              <router-link
                to="/industrialVideo/industrialVideo"
                style="color:#ffffff;text-decoration: none;"
              >解决方案</router-link>&nbsp;▪&nbsp;
              <router-link
                to="/substationProject/substationProject"
                style="color:#ffffff;text-decoration: none;"
              >工程案例</router-link>&nbsp;▪&nbsp;
              <router-link
                to="/enterpriseMind/enterpriseMind"
                style="color:#ffffff;text-decoration: none;"
              >企业文化</router-link>&nbsp;▪&nbsp;
              <router-link to="/contactus" style="color:#ffffff;text-decoration: none;">联系我们</router-link>
            </div>
            <span>技术支持：{{ siteInfo.customs.company }}</span>
            <!-- <div class="footer-left_buttom">
              <span>&copy;2024&nbsp;{{siteInfo.company}}&nbsp;&nbsp;</span>
            </div>-->
          </div>
          <div class="footer-mid">
            <div class="footer-right_top">
              <span>咨询热线</span>
            </div>
            <el-divider></el-divider>
            <div class="footer-right_buttom">
              <p>
                {{siteInfo.customs.telephone}}
                <span>(24小时服务)</span>
              </p>
            </div>
          </div>
          <div class="footer-right">
            <div class="footer-right_top">
              <span>
                <router-link to="/contactus">联系我们</router-link>
              </span>
            </div>
            <el-divider></el-divider>
            <div class="footer-right_buttom">
              <div class="right_in">
                <i class="el-icon-location-outline"></i>
                {{ siteInfo.customs.address }}
              </div>
              <div class="right_in">
                <img src="../assets/beian.png" />
                <a href="https://beian.miit.gov.cn" target="_blank">{{ siteInfo.customs.icp }}-1</a>
              </div>
              <span>{{ siteInfo.customs.recordcode }}</span>
            </div>
          </div>
        </div>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import { getSite } from '@/api/index'
export default {
  name: 'NavPage',
  props: {
    msg: String
  },
  data() {
    return {
      // activeIndex: '/',
      isShowSubMenu: true,
      channelParams: {
        parent: ''
      },
      siteInfo: {}
    }
  },
  mounted() {
    this.getSiteInfo()
  },
  methods: {
    getSiteInfo() {
      getSite().then(res => {
        this.siteInfo = res.data
        console.log(this.siteInfo)
      })
    },
    handleSelect(key, keyPath) {
      // console.log(key, keyPath)
    }
  }
}
</script>

<style scoped>
.el-container {
  /* width: 100%; */
  min-height: 100vh; /* 确保容器至少占据整个视口的高度 */
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}
/* header */
.el-header {
  height: 100px;
  padding: 0;
  position: relative;
}
.nav {
  width: 100%;
  /* height: 100px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  /* background-color: skyblue; */
}
.el-menu.el-menu--horizontal {
  border: none;
}
@media (min-width: 2000px) {
  .nav {
    width: 100%;
    height: 100px;
    position: relative;
    /* background-color: pink; */
    /* 在屏幕宽度大于或等于600px时，宽度设置为50% */
  }
}
.nav_left {
  width: 40%;
  height: 100px;
  line-height: 100px;
  background-color: #545c64;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: pink; */
}
.nav_right {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* background-color: rgb(37, 165, 245); */
}
.nav_left img {
  width: 400px;
  height: 100px;
  line-height: 100px;
}
/* elementui */
.el-menu-demo {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.el-submenu /deep/ .el-submenu__title {
  font-size: 18px;
}
.about {
  font-size: 18px;
}
/* el-main */
.el-main {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 0;
}
/* footer */
.el-footer {
  margin: 0;
  padding: 0;
}
.footer {
  /* background-color: #005bac; */
  /* background-image: url(../assets/pagingBanner1.jpg); */
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: inherit;
  position: relative;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.footer::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(20px); /* 背景模糊 */
  opacity: 0.9;
}

.footer .footer-left {
  width: 30%;
  height: inherit;
  float: left;
  z-index: 999;
  /* background-color: #545c64; */
}
.footer-left_top {
  width: 100%;
  height: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 12px;
  /* outline: 1px solid red; */
}
.footer-left span {
  color: #fff;
}
/* .footer-left_top .router-link-exact-active,
.router-link-active {
  text-decoration: none;
  color: white;
} */
.footer-left_buttom span {
  font-size: 16px;
  color: #fff;
  font-weight: bold;
}

.footer-left_buttom a {
  font-weight: bold;
  color: #fff;
}

.footer a:hover {
  text-decoration: underline;
}
.footer-mid {
  width: 30%;
  height: inherit;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: column;
  z-index: 999;
  /* outline: 1px solid red; */
}

.footer-right_top span {
  display: inline-block;
  width: 50%;
  height: 40px;
  line-height: 50px;
  text-align: left;
  margin: 0;
  padding: 0;
  color: white;
  font-size: 20px;
  /* outline: 1px solid red; */
}
.footer-right_top span a {
  color: #fff;
  text-decoration: none;
}
.footer-right_buttom p {
  color: white;
  font-size: 36px;
  margin: 0;
  padding: 0;
}
.footer-right_buttom p span {
  color: white;
  font-size: 20px;
}
.footer .footer-right {
  width: 30%;
  height: inherit;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  z-index: 2;
  /* float: right;
  text-align: right; */
  color: white;
  /* background-color: aliceblue; */
}

.footer .footer-right img {
  position: relative;
  /* top: -2px; */
  margin-right: 5px;
}

.footer .footer-right i {
  margin-right: 5px;
  position: relative;
  top: 2px;
  font-size: 20px;
}

.footer-right_top {
  width: 100%;
  height: 40%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: column;
}
.footer-right_buttom {
  width: 100%;
  height: 60%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.right_in {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  margin-top: 10px;
}
.right_in a {
  color: white;
  text-decoration: none;
}
.footer-right_buttom span {
  margin-top: 10px;
}
/* 响应式布局 */
@media screen and (max-width: 768px) {
  .container {
    flex-direction: column; /* 列方向 */
  }
  main {
    flex-direction: column; /* 列方向 */
  }
}
</style>
