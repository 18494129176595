<template>
  <div class="box" v-if="detailData">
    <div class="top">
      <img :src="detailData.site.url + detailData.customs.backImg" alt="空白" />
    </div>
    <div class="buttom">
      <h2>{{ detailData.title }}</h2>
      <div class="main" v-html="detailData.text"></div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      detailData: null
    }
  },
  created() {
    // 在组件创建时获取传递过来的数据
    this.detailData = this.$route.params.data
  }
}
</script>
<style scoped>
.box {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  /* outline: 1px solid red; */
}
@media (min-width: 2000px) {
  .box {
    width: 100%; /* 在屏幕宽度大于或等于600px时，宽度设置为50% */
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    /* outline: 1px solid red; */
  }
}
.top {
  width: 100%;
  height: 300px;
  position: relative;
  /* outline: 1px solid red; */
}
.top img {
  width: 100%;
  height: 100%;
}
.buttom {
  width: 80%;
  margin-top: -80px;
  background-color: white;
  /* outline: 1px solid red; */
}
.main {
  min-height: 300px;
  margin-top: 80px;
  padding: 0 20px 0 20px;
}
.main img {
  width: 80%;
  height: 200px;
}
</style>