import request from '../utils/request'

/**
 * 获取文章列表
 * @param {*} params {channel: 栏目别名, isIncludeSubChannel: 是否包含子栏目文章默认true}
 * @param {*} method 请求方法
 * @returns
 */
export function getArticle (params, method = 'get') {
  return request({
    url: '/article',
    method,
    params
  })
}

/**
 * 获取站点信息
 * @param {*} method 请求方式
 * @returns
 */
export function getSite (method = 'get') {
  return request({
    url: '/site/current',
    method
  })
}

/**
 * 获取栏目列表
 * @param {*} params {parent: 上级栏目别名}
 * @param {*} method 请求方式
 * @returns
 */
export function getChannelList (params, method = 'get') {
  return request({
    url: '/channel',
    params,
    method
  })
}