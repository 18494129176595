/**
* 参数处理
* @param {*} params  参数
*/
export function tansParams (params) {
  let result = ''
  for (const propName of Object.keys(params)) {
    const value = params[propName]
    const part = encodeURIComponent(propName) + '='
    if (value !== null && value !== '' && typeof (value) !== 'undefined') {
      if (typeof value === 'object') {
        for (const key of Object.keys(value)) {
          if (value[key] !== null && value[key] !== '' && typeof (value[key]) !== 'undefined') {
            const params = propName + '[' + key + ']'
            const subPart = encodeURIComponent(params) + '='
            result += subPart + encodeURIComponent(value[key]) + '&'
          }
        }
      } else {
        result += part + encodeURIComponent(value) + '&'
      }
    }
  }
  return result
}

/**
 * 对象数组数据处理 根据某字段排序 sticky
 * @param arr 对象数组
 * @param paramName 排序所依据的变量名
 * @param sortType 排序方式 默认为desc 可选asc
 * @return 新数组
 */
export function dataSortByParam (arr, paramName, sortType = 'desc') {
  const newArr = JSON.parse(JSON.stringify(arr))
  // 降序
  if (sortType === 'desc') {
    newArr.sort((a, b) => {
      return b[paramName] - a[paramName]
    })
  }
  // 升序
  if (sortType === 'asc') {
    newArr.sort((a, b) => {
      return a[paramName] - b[paramName]
    })
  }
  return newArr
}