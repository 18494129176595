import Vue from 'vue'
import VueRouter from 'vue-router'
import AboutView from '../views/AboutView.vue'
import SoluTion from '../views/SoluTion.vue'
import ProjectCase from '../views/ProjectCase.vue'
import EnterPrise from '../views/EnterPrise.vue'
import ContactUs from '../views/ContactUs.vue'
import ProjectDetails from '../views/ProjectDetails.vue'
import ProductDetails from '../views/ProductDetails.vue'
import PlanDetails from '../views/PlanDetails.vue'
import NewDetaile from '../views/NewDetaile.vue'
import QualificaTion from '../views/QualificaTion.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'aboutview',
    component: AboutView
  },
  // 公司概况
  {
    path: '/introduction/:name',
    name: 'introduction',
    component: QualificaTion
  },
  {
    path: '/qualification/:name',
    name: 'qualification',
    component: QualificaTion
  },
  {
    path: '/framework/:name',
    name: 'framework',
    component: QualificaTion
  },
  {
    path: '/teamer/:name',
    name: 'teamer',
    component: QualificaTion
  },
  {
    path: '/news/:name',
    name: 'news',
    component: QualificaTion
  },
  // 新闻详情页
  {
    path: '/newdetaile',
    name: 'newdetaile',
    component: NewDetaile
  },
  // 解决方案
  {
    path: '/industrialVideo/:name',
    name: 'industrialVideo',
    component: SoluTion
  },
  {
    path: '/smartAssist/:name',
    name: 'smartAssist',
    component: SoluTion
  },
  {
    path: '/securityControl/:name',
    name: 'securityControl',
    component: SoluTion
  },
  // 方案详情页
  {
    path: '/planpetails',
    name: 'plandetails',
    component: PlanDetails
  },
  // 工程案例
  {
    path: '/substationProject/:name',
    name: 'substationProject',
    component: ProjectCase
  },
  {
    path: '/hydropowerProject/:name',
    name: 'hydropowerProject',
    component: ProjectCase
  },
  {
    path: '/trafficProject/:name',
    name: 'trafficProject',
    component: ProjectCase
  },
  {
    path: '/industrialservice/:name',
    name: 'industrialservice',
    component: ProjectCase
  },
  // 工程详情页
  {
    path: '/projectdetails',
    name: 'projectdetails',
    component: ProjectDetails,
    props: true
  },
  // 企业文化
  {
    path: '/enterpriseMind/:name',
    name: 'enterpriseMind',
    component: EnterPrise
  },
  {
    path: '/enterpriseStyle/:name',
    name: 'enterpriseStyle',
    component: EnterPrise
  },
  {
    path: '/partners/:name',
    name: 'partners',
    component: EnterPrise
  },
  // 招聘
  {
    path: '/contactus',
    name: 'contactus',
    component: ContactUs
  },
  // {
  //   path: '/zhaopin',
  //   name: 'zhaopin',
  //   component: ZhaoPin
  // },
  // 产品项目详情
  {
    path: '/productdetails',
    name: 'productdetails',
    component: ProductDetails
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
